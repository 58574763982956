import { ComponentsOverrides, Theme } from "@mui/material";

const MuiTableCell: ComponentsOverrides<Theme>["MuiTableCell"] = {
  root: {
    borderBottom: "none",
    padding: "6px",
  },
  head: {
    color: "#7A7C82",
    fontSize: 12,
  },
};

export default MuiTableCell;
