import { CSSProperties, FC, useEffect } from "react";
import Props from "./Props";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useStyles } from "./styles";
import clsx from "clsx";
import Flex from "../../Flex";

const Loader: FC<Props> = (props) => {
  const {
    wrapperClassName,
    textClassName,
    withMask,
    fullScreen,
    visible,
    maskOpacity,
    title,
    children,
    ...rest
  } = props;
  const { classes } = useStyles();
  const additionalStyle: CSSProperties = {};

  useEffect(() => {
    if (!visible || !fullScreen) {
      return;
    }

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = "";
    };
  }, [fullScreen, visible]);

  if (!visible) {
    return null;
  }

  if (maskOpacity) {
    additionalStyle.backgroundColor = `rgba(255,255,255,${maskOpacity})`;
  }

  return (
    <Box
      className={clsx(
        classes.root,
        withMask && classes.withMask,
        fullScreen && classes.fullScreen,
        wrapperClassName
      )}
      style={additionalStyle}>
      <Flex
        direction={"column"}
        alignVertical={"center"}
        alignHorizontal={"center"}>
        <CircularProgress className={classes.circularProgress} {...rest} />
        {children || title ? (
          <Typography
            color={"textSecondary"}
            className={clsx(classes.title, textClassName)}
            variant={"body2"}>
            {title}
          </Typography>
        ) : null}
      </Flex>
    </Box>
  );
};

export default Loader;
