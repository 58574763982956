import { ComponentsOverrides, Theme } from "@mui/material";

const MuiChip: ComponentsOverrides<Theme>["MuiChip"] = {
  root: {
    borderRadius: 4,
    height: 26,
    border: "1px solid #E4E7EF",

    "&.MuiChip-filled": {
      backgroundColor: "#458CFF",
    },
  },
  outlined: {
    "& .MuiChip-label": {
      color: "#4D5773",
      fontSize: 13,
    },
  },
  clickable: {
    "& .MuiChip-label": {
      color: "#fff",
    },
    "&:hover": {
      opacity: "0.85",
    },
    "&:focus": {
      opacity: "0.85",
    },
  },
  deletable: {
    backgroundColor: "#458CFF",
    "& .MuiChip-label": {
      color: "#fff",
    },
    "&:hover": {
      backgroundColor: "#458CFF",
      opacity: "0.85",
    },
    "&:focus": {
      opacity: "0.85",
    },
  },
  deleteIcon: {
    width: 14,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};

export default MuiChip;
