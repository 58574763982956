import React, { FC, PropsWithChildren } from "react";
import { VacanciesFilterContext } from "./VacanciesFilterContext";
import { defaultVacanciesFilter } from "./defaultVacanciesFilter";
import { useFilter } from "../../../Filter/useFilter";
import { VacanciesFilter } from "./models/VacanciesFilter";

const VacanciesFilterContextProvider: FC<
  PropsWithChildren<{ defaultFilters?: Partial<VacanciesFilter> }>
> = ({ children, defaultFilters }) => {
  const context = useFilter({ ...defaultVacanciesFilter, ...defaultFilters });

  return (
    <VacanciesFilterContext.Provider value={context}>
      {children}
    </VacanciesFilterContext.Provider>
  );
};

export default VacanciesFilterContextProvider;
