import { ComponentsOverrides, Theme } from "@mui/material";

const MuiButton: ComponentsOverrides<Theme>["MuiButton"] = {
  root: {
    fontWeight: 600,
    fontSize: 14,
    textTransform: "none",
  },
  contained: {
    color: "white",
    borderRadius: 4,
    backgroundColor: "#458CFF",
    boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.06);",
    "&:hover": {
      backgroundColor: "#0271C3",
      boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.06);",
      "@media (hover: none)": {
        backgroundColor: "#0271C3",
        boxShadow: "0px 1px 1px rgba(0, 0, 0, 0.06);",
      },
    },
  },
  text: {
    color: "#458CFF",
    "&:hover": {
      backgroundColor: "rgba(69,140,255,0.07)",
      "@media (hover: none)": {
        backgroundColor: "rgba(69,140,255,0.07)",
      },
    },
  },
  outlined: {
    "&:hover": {
      backgroundColor: "rgba(69,140,255,0.05)",
      "@media (hover: none)": {
        backgroundColor: "rgba(69,140,255,0.05)",
      },
    },
  },
  containedSecondary: {
    color: "#458CFF",
    backgroundColor: "#FFFFFF",
    minHeight: 48,
    boxShadow: "0px 2px 16px rgb(121 121 121 / 12%)",
    "&:hover": {
      backgroundColor: "rgba(69,140,255,0.05)",
      boxShadow: "0px 2px 16px rgb(121 121 121 / 12%)",
      "@media (hover: none)": {
        backgroundColor: "rgba(69,140,255,0.05)",
        boxShadow: "0px 2px 16px rgb(121 121 121 / 12%)",
      },
    },
  },
};

export default MuiButton;
