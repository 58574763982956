import { ComponentsOverrides, Theme } from "@mui/material";

const MuiGrid: ComponentsOverrides<Theme>["MuiGrid"] = {
  root: {
    "&.MuiGrid-item": {
      overflow: "visible",
    },
  },
};

export default MuiGrid;
