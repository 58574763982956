import { CandidatesFilter } from "./models/CandidatesFilter";
import {
  BUDGET_MAX,
  BUDGET_MIN,
  EXPERIENCE_MAX,
  EXPERIENCE_MIN,
} from "../../../../../config";

export const defaultCandidatesFilter: CandidatesFilter = {
  query: "",
  order: "",
  skill: [],
  city: [],
  englishLevel: [],
  seniority: [],
  budget: {
    max: BUDGET_MAX,
    min: BUDGET_MIN,
  },
  experience: {
    max: EXPERIENCE_MAX,
    min: EXPERIENCE_MIN,
  },
};
