import { useContext } from "react";
import { AuthContext } from "../components/AuthContextProvider";
import { Role } from "@/generated/types";

export function useIsRecommender() {
  const { accessToken, role } = useContext(AuthContext);

  return (
    accessToken !== undefined &&
    (role === Role.Recruiter || role === Role.Candidate)
  );
}
