import {
  ChatDocument,
  ChatsDocument,
  useNewChatMessageSubscription,
  useViewMessageSubscription,
} from "@/pages/chat/operations.generated";
import { useCallback, useEffect, useRef } from "react";
import { client } from "@/config/apollo";
import { MessageFragmentDoc } from "@/pages/chat/fragments.generated";
import { useHistory } from "react-router-dom";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { showAlert } from "@/common/utils/customEvents";
import { NewChatMessagesCountDocument } from "@/pages/chat/components/ChatMessagesIconButton/ChatMessagesIconButton.operations.generated";
import { PaidPlanName } from "@/generated/types";
import { useIsChatUser } from "@/common/hooks/useIsChatUser";
import { D_CHAT } from "@/i18n/dictionary/chat.dictionary";

export function useChatMessageSubscriptions() {
  const skip = !useIsChatUser();
  const history = useHistory();
  const addedMessage = useRef<any>(null);
  const { data: newChatMessageSubscriptionData } =
    useNewChatMessageSubscription({
      shouldResubscribe: true,
      fetchPolicy: "no-cache",
      skip,
    });
  const newChatMessage = newChatMessageSubscriptionData?.newChatMessage;
  const { data: viewMessageSubscriptionData } = useViewMessageSubscription({
    shouldResubscribe: true,
    fetchPolicy: "no-cache",
    skip,
  });

  const openChatAction = useCallback(
    (chatId: string) => {
      return () => {
        history.push(
          getRouteWithSlash(AppRoute.CHAT_VIEW).replace(":id", chatId)
        );
      };
    },
    [history]
  );

  useEffect(() => {
    const viewMessage = viewMessageSubscriptionData?.viewMessage;
    if (viewMessage) {
      setTimeout(
        () =>
          client.cache.updateFragment(
            {
              fragment: MessageFragmentDoc,
              id: "ChatMessage:" + viewMessage.id,
            },
            (prevMessage: any) => {
              return { ...prevMessage, isNew: false };
            }
          ),
        2000
      );
    }
  }, [viewMessageSubscriptionData?.viewMessage]);

  useEffect(() => {
    if (newChatMessage) {
      if (
        !newChatMessage.isMine &&
        newChatMessage.chat?.id &&
        !location.pathname.includes(newChatMessage.chat?.id)
      ) {
        client.cache.updateQuery(
          {
            query: NewChatMessagesCountDocument,
          },
          (data) => {
            const prevCount = data?.newMessagesCount;

            if (data && typeof prevCount === "number") {
              return { ...data, newMessagesCount: data.newMessagesCount + 1 };
            }

            return data;
          }
        );
      }

      client.cache.updateQuery(
        {
          query: ChatsDocument,
          variables: { plan: PaidPlanName.Premium },
        },
        (data) => {
          const chats = data?.chats;

          if (chats) {
            const newChats = chats.map((chat: any) => {
              const newChat = { ...chat };

              if (newChat.id === newChatMessage.chat?.id) {
                newChat.lastMessage = newChatMessage;

                if (
                  !newChatMessage.isMine &&
                  newChatMessage.chat?.id &&
                  !location.pathname.includes(newChatMessage.chat?.id)
                ) {
                  showAlert(
                    newChatMessage.content,
                    "info",
                    D_CHAT.notificationTitle(newChat.name),
                    openChatAction(newChat.id)
                  );
                  newChat.newQty++;
                }
              }

              return newChat;
            });

            return { ...data, chats: newChats };
          }

          return data;
        }
      );
    }

    if (
      newChatMessage &&
      !newChatMessage.isMine &&
      addedMessage.current?.id !== newChatMessage.id
    ) {
      const lastMessage = newChatMessage;
      addedMessage.current = lastMessage;

      client.cache.updateQuery(
        {
          query: ChatDocument,
          variables: { chatId: newChatMessage.chat?.id },
        },
        (prev) => {
          if (!prev || !prev.chat || !prev.chat.messages) {
            return prev;
          }

          return {
            ...prev,
            chat: {
              ...prev.chat,
              messages: [...prev.chat?.messages, lastMessage],
            },
          };
        }
      );
    }
  }, [newChatMessage, openChatAction]);
}
