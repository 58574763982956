import { useCallback, useMemo } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
  FilterClickable,
  FilterRange,
} from "../components/Filter/models/FilterType";
import { IFilter } from "../components/Filter/models/IFilter";
import cloneDeep from "lodash.clonedeep";

export const useSearchParams = () => {
  const history = useHistory();
  const location = useLocation();
  const searchParams = useMemo(
    () => new URLSearchParams(location.search),
    [location.search]
  );

  const addSearchParams = () => {
    history.replace({
      search: searchParams.toString(),
    });
  };

  const parseJson = (jsonString: string | null) => {
    if (jsonString === null) {
      return null;
    }

    let validJson = null;

    try {
      validJson = JSON.parse(jsonString);
    } catch (err) {
      console.error("Json string is not valid", err);
    }

    return validJson;
  };

  const updateSearch = (
    key: string,
    value?: FilterClickable | FilterRange | string | number,
    isExistDefaultFilter?: boolean
  ) => {
    if (value === undefined) {
      searchParams.delete(key);
      addSearchParams();
      return;
    }

    if (typeof value === "string" || typeof value === "number") {
      searchParams.set(key, value.toString());
      addSearchParams();

      return;
    }

    const newValue = { ...value };

    if ("__typename" in newValue) {
      delete newValue.__typename;
    }

    const jsonString = JSON.stringify(newValue);

    if ("id" in newValue) {
      const searchParts = searchParams.getAll(key);

      if (isExistDefaultFilter) {
        return;
      }

      if (!searchParts.includes(jsonString)) {
        searchParams.append(key, jsonString);
        addSearchParams();

        return;
      }

      searchParams.delete(key);

      searchParts.forEach((part) => {
        part !== jsonString && searchParams.append(key, part);
      });
    } else {
      searchParams.set(key, jsonString);
    }

    addSearchParams();
  };

  const getFilterWithSearch = <F extends IFilter>(defaultFilter: F) => {
    const filterWithSearch = cloneDeep(defaultFilter);

    for (const key in filterWithSearch) {
      const value = filterWithSearch[key];

      let parts;
      let parseParts;

      if (Array.isArray(value)) {
        parts = searchParams.getAll(key);
        parseParts = parts.length
          ? parts.map((part) => parseJson(part)).filter((part) => part !== null)
          : [];
      } else if (typeof value === "string") {
        parseParts = searchParams.get(key);
      } else {
        parts = searchParams.get(key);
        parseParts = parseJson(parts);
      }

      if (parseParts !== null) {
        filterWithSearch[key] = parseParts;
      }
    }

    return filterWithSearch;
  };

  const getSearchParams = useCallback(
    (searchKey: string) => {
      return searchParams.get(searchKey);
    },
    [searchParams]
  );

  const getAllSearchParams = useCallback(
    (searchKey: string) => {
      return searchParams.getAll(searchKey);
    },
    [searchParams]
  );

  const clearSearchParams = () => {
    history.replace({
      search: "",
    });
  };

  return {
    getFilterWithSearch,
    updateSearch,
    getSearchParams,
    getAllSearchParams,
    clearSearchParams,
  };
};
