import components from "./components";
import typography from "./typography";
import { createTheme, Theme, ThemeOptions } from "@mui/material";

type AppThemeOptions = ThemeOptions;
export type AppTheme = Theme;

const appTheme: AppThemeOptions = {
  components,
  typography,
  palette: {
    primary: {
      main: "#458CFF",
    },
  },
};

export default createTheme(appTheme);
