import { FC } from "react";
import ContentLoader from "react-content-loader";

export const VacanciesDesktopLoader: FC = () => (
  <ContentLoader
    speed={2}
    width={1435}
    height="100%"
    viewBox="0 0 1435 987"
    style={{ margin: "auto" }}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">
    <path d="M 16 14 h 1403 v 43 H 16 z M 16 70.5 h 260 v 519 H 16 z M 300 77.09 h 1119 v 55 H 300 z" />
    <rect x="300" y="148" rx="6" ry="6" width="552" height="194" />
    <rect x="868" y="148" rx="6" ry="6" width="552" height="194" />
    <rect x="300" y="358" rx="6" ry="6" width="552" height="194" />
    <rect x="868" y="358" rx="6" ry="6" width="552" height="194" />
    <rect x="300" y="567" rx="6" ry="6" width="552" height="194" />
    <rect x="868" y="567" rx="6" ry="6" width="552" height="194" />
    <rect x="300" y="775" rx="6" ry="6" width="552" height="194" />
    <rect x="868" y="775" rx="6" ry="6" width="552" height="194" />
  </ContentLoader>
);
