import React, { FC } from "react";
import { Button as MuiButton } from "@mui/material";

import StyledButtonProps from "./StyledButtonProps";
import Loader from "../loaders/Loader";
import { useStyles } from "./styles";
import clsx from "clsx";

const Button: FC<StyledButtonProps> = (props: StyledButtonProps) => {
  const { loading, children, additional, className, ...rest } = props;
  const { classes } = useStyles();

  return (
    <MuiButton
      className={clsx(
        classes.root,
        additional && classes.additional,
        className
      )}
      {...rest}>
      {children}
      <Loader withMask size={25} visible={Boolean(loading)} maskOpacity={0.7} />
    </MuiButton>
  );
};

export default Button;
