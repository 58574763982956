import React from "react";
import { ICandidatesFilterContext } from "./models/ICandidatesFilterContext";
import { defaultCandidatesFilter } from "./defaultCandidatesFilter";

export const CandidatesFilterContext =
  React.createContext<ICandidatesFilterContext>({
    filter: defaultCandidatesFilter,
    applyFilter: () => undefined,
    reset: () => undefined,
    applyFilters: () => undefined,
    clearFilter: () => undefined,
  });
