const typography = {
  fontWeightRegular: 500,
  fontFamily: [
    "Montserrat",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
  ].join(","),
  h1: {
    fontWeight: 600,
    fontSize: "24px",
    fontStyle: "normal",
    letterSpacing: "0.2px",
    lineHeight: 1.25,
    "@media (max-width: 600px)": {
      fontSize: "1.25rem",
    },
  },
  h2: {
    fontSize: 18,
    fontWeight: 700,
    color: "#0A0A0A",
    marginBottom: 24,
  },
  h3: {
    fontSize: 16,
    fontWeight: 700,
    color: "#0A0A0A",
    marginBottom: 16,
  },
};

export default typography;
