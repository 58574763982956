import { Dictionary } from "@/i18n/dictionary.types";
import chatUA from "@/i18n/dictionary/ua/chat";
import chatEN, { DChatType } from "@/i18n/dictionary/en/chat";
import { t } from "@/i18n/t";

const CHAT: Dictionary<DChatType> = {
  ua: chatUA,
  en: chatEN,
};

export const D_CHAT = t(CHAT);
