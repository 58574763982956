import React from "react";
import { ICompaniesGridFilterContext } from "./models/ICompaniesGridFilterContext";
import { defaultCompaniesGridFilter } from "./defaultCompaniesGridFilter";

export const CompaniesGridFilterContext =
  React.createContext<ICompaniesGridFilterContext>({
    filter: defaultCompaniesGridFilter,
    applyFilter: () => undefined,
    applyFilters: () => undefined,
    clearFilter: () => undefined,
    reset: () => undefined,
  });
