import { UserLevel } from "@/common/models/UserLevel";

const ua = Object.freeze({
  // const ua: Record<string, Record<string, any>> = Object.freeze({
  autocomplete: {
    title: "Співпадіння",
    section: (entity: string, qty: number) => `Знайдено ${qty} ${entity}`,
  },
  userLevel: {
    levelDescription: (level: UserLevel, percent: number, amount?: number) =>
      `Ваш поточний рівень на Matchit - "${level}". На цьому рівні ви можете отримати бонус до ${percent}% від зарплати кандидата ${
        amount ? "та додатково $" + amount + " фіксованої винагороди" : ""
      } з кожної рекомендації! Більше рекомендацій - Вищий рівень - Більший бонус!💰`,
  },
  relatedVacancies: {
    title: "Вакансії, які можуть вас зацікавити",
  },
  senioritySelectPicker: {
    label: "Рівень",
    placeholder: "Виберіть Рівень",
  },
  cookiesBanner: {
    acceptButton: "Погоджуюсь",
    message:
      "Ми використовуємо файли cookies браузера для деяких потреб програми. Натиснувши кнопку «Погоджуюсь», ви підтверджуєте використання файлів cookies",
  },
  privacyPolicy: {
    pageTitle: "Політика Конфіденційності",
  },
  contactUs: {
    pageTitle: "Зв'язатися з нами",
  },
  aboutUs: {
    pageTitle: "Про Нас",
  },
  telegram: {
    pageTitle: "Telegram",
  },
  userAgreement: {
    pageTitle: "Угода Користувача",
  },

  recommendatorWallet: {
    pageTitle: "Мій Гаманець",
  },
  recommendatorReporting: {
    pageTitle: "Аналітика",
  },
  underConstruction: {
    title: "Ця сторінка знаходиться в стадії розробки",
    description: "Будь ласка, перевірте найближчим часом...",
  },
  experience: {
    year: "р.",
    years: "р.",
  },
  dialog: {
    accept: "Підтвердити",
    cancel: "Скасувати",
  },
  adminLogin: {
    login: "Увійти",
  },
  warning: {
    recommendedCandidateForVacancy:
      "Вірогідність успішного найму буде вищою на 95%, а бонус удвічі більший, якщо кандидат буде проінформований, що його рекомендують",
  },
  dropzone: {
    description: "Перетягніть файл сюди",
    descriptionOnPhone: "Завантажити резюме",
  },
  confirmationDialog: {
    title: "Ви впевнені?",
    acceptButtonText: "Підтвердити",
  },
  citySelect: {
    placeholder: "Почніть вводити...",
    label: "Локації",
  },
  companySelect: {
    placeholder: "Компанія",
    label: "Компанія",
  },
  companyTypeSelect: {
    placeholder: "Тип Компанії",
    label: "Тип Компанії",
  },
  englishLevelSelectPicker: {
    label: "Рівень англійської",
    placeholder: "Будь ласка, оберіть необхідний рівень англійської",
  },
  plan: {
    pro: "Pro вакансії - це баланс між розміром бонуса та швидкістю процесингу кандидата. Команда Matchit подбає про те, щоб ви завжди отримували якісний фідбек по ваших рекомендаціях.",
    proCandidate:
      "Pro вакансії - це баланс між розміром бонуса та швидкістю процесингу кандидата. Команда Matchit подбає про те, щоб ви завжди отримували якісний фідбек по ваших заявках.",
    premium:
      "Premium вакансії повністю модеруються командою Matchit. Рекомендації розглядаються найшвидше, отримують найякісніший фідбек та мають найбільший бонус за найм.",
    premiumCandidate:
      "Premium вакансії повністю модеруються командою Matchit. Заявки розглядаються найшвидше, отримують найякісніший фідбек та мають найбільший бонус за найм.",
  },
  companiesFiler: {
    statusTitle: "Статус",
  },
  searchField: {
    placeholder: "Почніть вводити...",
  },
  recommendatorRecommendations: {
    pageTitle: "Мої Рекомендації",
    pageTitleCandidate: "Заявки на роботу",
    foundRecommendations: (recommendationsQty: number) =>
      `Знайдено ${recommendationsQty} рекомендацій`,
  },
  englishLevel: {
    notSpecified: "Будь-який",
  },
});

export default ua;
