import { FC } from "react";
import ContentLoader from "react-content-loader";

export const CompaniesDesktopLoader: FC = () => (
  <ContentLoader
    speed={2}
    width={1435}
    height="100%"
    viewBox="0 0 1435 987"
    style={{ margin: "auto" }}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">
    <path d="M 16 14 h 1403 v 43 H 16 z M 16 77.59 h 1403 v 55 H 16 z" />
    <rect x="16" y="152" rx="6" ry="6" width="456" height="164" />
    <rect x="963" y="152" rx="6" ry="6" width="456" height="164" />
    <rect x="489" y="152" rx="6" ry="6" width="456" height="164" />
    <rect x="16" y="506" rx="6" ry="6" width="456" height="164" />
    <rect x="963" y="506" rx="6" ry="6" width="456" height="164" />
    <rect x="489" y="506" rx="6" ry="6" width="456" height="164" />
    <rect x="16" y="329" rx="6" ry="6" width="456" height="164" />
    <rect x="963" y="329" rx="6" ry="6" width="456" height="164" />
    <rect x="489" y="329" rx="6" ry="6" width="456" height="164" />
    <rect x="16" y="683" rx="6" ry="6" width="456" height="164" />
    <rect x="963" y="683" rx="6" ry="6" width="456" height="164" />
    <rect x="489" y="683" rx="6" ry="6" width="456" height="164" />
  </ContentLoader>
);
