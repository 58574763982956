import React, {
  FC,
  ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { getSubscriptionClient } from "@/apollo/subscriptionClient";
import { useCachedState } from "@/common/hooks/useCachedState";
import { useRenderDialog } from "@/common/hooks/useRenderDialog";
import { pushGTMEvent } from "@/common/utils/gtmUtils";
import { Role } from "@/generated/types";
import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { RootStorage } from "@/store/models/RootStorage";
import { useHistory } from "react-router-dom";
import { Tokens } from "../../models/Tokens";
import { AuthStorage } from "@/store/models/AuthStorage";
import { defaultStorageState } from "@/store/components/AsyncStorageContextProvider";
import Cookies from "js-cookie";
import { CookieKeys } from "@/common/models/CookieKeys";

interface Props {
  children?: React.ReactElement | ReactElement[];
}

type SetAuthActionType = (authState: AuthStorage) => void;
type RegisterUserActionType = (tokens: Tokens, role: Role) => void;
type LoginActionType = (tokens: Tokens, role: Role) => void;
type LogoutActionType = () => void;
type ShowLoginDialogActionType = (
  signUpPath: AppRoute,
  email?: string,
  redirectPath?: string
) => void;
type ShowForgotPasswordDialogActionType = () => void;

interface AuthContextInterface {
  accessToken?: string;
  refreshToken?: string;
  registerUser: RegisterUserActionType;
  setAuthStorage: SetAuthActionType;
  login: LoginActionType;
  showLoginDialog: ShowLoginDialogActionType;
  showForgotPasswordDialog: ShowForgotPasswordDialogActionType;
  logout: LogoutActionType;
  role: Role;
}

export const AuthContext = React.createContext<AuthContextInterface>({
  setAuthStorage: () => undefined,
  registerUser: () => undefined,
  login: () => undefined,
  showLoginDialog: () => undefined,
  showForgotPasswordDialog: () => undefined,
  logout: () => undefined,
  role: Role.Candidate,
});

const AuthContextProvider: FC<Props> = ({ children }) => {
  const history = useHistory();
  const [redirectPath, setRedirectPath] = useState<undefined | string>();
  const [signUpPath, setSignUpPath] = useState<AppRoute>(
    AppRoute.RECOMMENDATOR_SIGN_UP
  );
  const [loginDialogVisible, setLoginDialogVisible] = useState(false);
  const [prefilledEmail, setPrefilledEmail] = useState<string | null>(null);
  const [forgotPasswordDialogVisible, setForgotPasswordDialogVisible] =
    useState(false);
  const [authStorage, setAuthStorage] = useCachedState();
  const authData = useRef(window.localStorage.getItem("root"));
  const { accessToken, refreshToken, role } = authStorage;

  const login = useCallback(
    ({ accessToken, refreshToken }: Tokens, userRole: Role) => {
      try {
        setAuthStorage({ role: userRole, accessToken, refreshToken });
        getSubscriptionClient().restart();
      } catch (err) {
        console.error(err);
      }
    },
    [setAuthStorage]
  );

  const logout = useCallback(() => {
    setAuthStorage(defaultStorageState.auth);
    history.push({ pathname: getRouteWithSlash(AppRoute.HOME) });
  }, [history, setAuthStorage]);

  const registerUser = useCallback(
    (tokens: Tokens, userRole: Role) => {
      const newUserFrom = Cookies.get(CookieKeys.NEW_USER);

      login(tokens, userRole);
      pushGTMEvent({
        event: "registration_success",
        ref: newUserFrom,
        registration_user_role: userRole,
      });
    },
    [login]
  );

  const showLoginDialog = useCallback(
    (signUpPath: AppRoute, email?: string, loginRedirectPath?: string) => {
      setLoginDialogVisible(true);
      setSignUpPath(signUpPath);

      if (email) {
        setPrefilledEmail(email);
      }

      if (loginRedirectPath) {
        setRedirectPath(loginRedirectPath);
      }
    },
    []
  );

  const hideLoginDialog = useCallback(() => {
    setLoginDialogVisible(false);
    setRedirectPath(undefined);
  }, []);

  const showForgotPasswordDialog = useCallback(() => {
    setForgotPasswordDialogVisible(true);
  }, []);

  const hideForgotPasswordDialog = useCallback(() => {
    setForgotPasswordDialogVisible(false);
  }, []);

  const renderLoginDialog = useRenderDialog({
    dialogName: "login",
    dialogProps: {
      open: loginDialogVisible,
      onClose: hideLoginDialog,
      prefilledEmail,
      redirectPath,
      signUpPath,
    },
    visible: loginDialogVisible,
  });

  const renderForgotPasswordDialog = useRenderDialog({
    dialogName: "forgotPassword",
    dialogProps: {
      open: forgotPasswordDialogVisible,
      onClose: hideForgotPasswordDialog,
    },
    visible: forgotPasswordDialogVisible,
  });

  useEffect(() => {
    const auth = () => {
      const currAuthData = window.localStorage.getItem("root");

      if (
        !currAuthData ||
        (currAuthData && currAuthData === authData.current)
      ) {
        return;
      }

      authData.current = currAuthData;
      const { auth }: RootStorage = JSON.parse(currAuthData);
      const { role, accessToken, refreshToken } = auth;

      login({ accessToken, refreshToken }, role);
    };

    window.addEventListener("storage", auth);

    return () => window.removeEventListener("storage", auth);
  }, [login]);

  return (
    <AuthContext.Provider
      value={{
        showLoginDialog,
        showForgotPasswordDialog,
        accessToken,
        refreshToken,
        registerUser,
        login,
        role,
        setAuthStorage,
        logout,
      }}>
      {children}
      {renderLoginDialog()}
      {renderForgotPasswordDialog()}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
