import { useState } from "react";

export function useCookiesBanner() {
  const [visible, setVisible] = useState(
    localStorage.getItem("cookies_usage") !== "true"
  );

  const acceptCookies = () => {
    localStorage.setItem("cookies_usage", "true");
    setVisible(false);
  };

  return { visible, acceptCookies };
}
