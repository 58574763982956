import React, { useEffect } from "react";
import { ApolloProvider } from "@apollo/client";
import { client } from "./config/apollo";
import theme from "./theme";
import Router from "./router/components/Router";
import AsyncStorageContextProvider from "./store/components/AsyncStorageContextProvider";
import AuthContextProvider from "./auth/components/AuthContextProvider";
import { BrowserRouter } from "react-router-dom";
import CookiesBanner from "./sections/CookiesBanner";
import { ThemeProvider } from "@mui/material/styles";
import { CacheProvider } from "@emotion/react";
import { CssBaseline } from "@mui/material";
import createEmotionCache from "@/theme/createEmotionCache";

const cache = createEmotionCache();

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const prefilledReferralCode = searchParams.get("referralCode");

  useEffect(() => {
    if (prefilledReferralCode) {
      localStorage.setItem("referralCode", prefilledReferralCode);
    }
  }, [prefilledReferralCode]);

  return (
    <AsyncStorageContextProvider>
      <ApolloProvider client={client}>
        <CacheProvider value={cache}>
          <ThemeProvider theme={theme}>
            <CssBaseline />
            <BrowserRouter>
              <AuthContextProvider>
                <Router />
              </AuthContextProvider>
            </BrowserRouter>
            <CookiesBanner />
          </ThemeProvider>
        </CacheProvider>
      </ApolloProvider>
    </AsyncStorageContextProvider>
  );
}

export default App;
