import { FC } from "react";
import clsx from "clsx";
import { useStyles } from "./Paper.styles";
import { PaperProps } from "./Paper.types";

export const Paper: FC<PaperProps> = ({ children, className }) => {
  const { classes } = useStyles();

  return <div className={clsx(classes.root, className)}>{children}</div>;
};
