import { FC, lazy, Suspense } from "react";
import RouterSwitch from "@/router/components/RouterSwitch";
import Loader from "@/common/components/loaders/Loader";
import { useRouter } from "@/router/components/Router/useRouter";

const PushNotificationDialog = lazy(
  () => import("@/common/components/PushNotificationDialog")
);
const NewUpdatesDialog = lazy(
  () => import("@/common/components/NewUpdatesDialog")
);
const AlertsContainer = lazy(
  () => import("@/common/components/Alert/components/AlertsContainer")
);
const NewUpdates = lazy(() => import("@/common/components/NewUpdates"));

const Router: FC = () => {
  useRouter();

  return (
    <>
      <Suspense fallback={<Loader />}>
        <PushNotificationDialog />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <NewUpdates />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <NewUpdatesDialog />
      </Suspense>
      <Suspense fallback={<Loader />}>
        <AlertsContainer />
      </Suspense>
      <RouterSwitch />
    </>
  );
};

export default Router;
