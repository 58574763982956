import { ComponentsOverrides, Theme } from "@mui/material";

const MuiTextField: ComponentsOverrides<Theme>["MuiTextField"] = {
  root: {
    borderRadius: 4,
    borderColor: "#EBECF0",
    "& $input": {
      padding: 14,
    },
    "& $label": {
      transform: "translate(14px, 16px) scale(1)",
      fontSize: 14,
    },
    "& $formHelperText": {
      color: "red",
    },
    "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
      borderColor: "red !important",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#DDE1E5",
      },
      "&:hover fieldset": {
        borderColor: "#C1C7CD",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#C1C7CD",
      },
    },
  },
};

export default MuiTextField;
