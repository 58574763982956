import { ComponentsOverrides, Theme } from "@mui/material";

const MuiDialogActions: ComponentsOverrides<Theme>["MuiDialogActions"] = {
  root: {
    margin: "20px 0",
    padding: "8px 24px",
  },
};

export default MuiDialogActions;
