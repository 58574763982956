import { FC } from "react";
import ContentLoader from "react-content-loader";

export const HomeDesktopLoader: FC = () => (
  <ContentLoader
    speed={2}
    width={1444}
    height={"auto"}
    style={{ margin: "auto", maxHeight: "100%" }}
    viewBox="0 0 1444 988"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">
    <path d="M 20.5 13 h 1403 v 43 H 20.5 z M 595 566 h 232 v 44.5 H 595 z M 595 884 h 254 v 58.5 H 595 z" />
    <rect x="20" y="70" rx="20" ry="20" width="687" height="406" />
    <rect x="737" y="70" rx="20" ry="20" width="687" height="406" />
    <rect x="967" y="640" rx="6" ry="6" width="457" height="194" />
    <rect x="493" y="640" rx="6" ry="6" width="457" height="194" />
    <rect x="20" y="640" rx="6" ry="6" width="457" height="194" />
  </ContentLoader>
);
