import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    position: "fixed",
    padding: theme.spacing(3),
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1000,
    boxShadow: "0px -10px 16px rgba(121, 121, 121, 0.12) !important",
  },
  acceptButton: {
    minWidth: 200,
    marginTop: theme.spacing(2),
  },
  container: {
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
    },
  },
}));
