import { AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { ALLOWED_LOCALITIES, Locality } from "@/config";

function stringToSlug(str: string) {
  str = str.replace(/^\s+|\s+$/g, ""); // trim
  str = str.toLowerCase();

  // remove accents, swap ñ for n, etc
  const from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;";
  const to = "aaaaeeeeiiiioooouuuunc------";
  for (let i = 0, l = from.length; i < l; i++) {
    str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
  }

  str = str
    .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
    .replace(/\s+/g, "-") // collapse whitespace and replace by -
    .replace(/-+/g, "-"); // collapse dashes

  return str;
}

export const openCandidateProfile = (candidateId: string) => {
  return getRouteWithSlash(AppRoute.CANDIDATE_VIEW).replace(
    ":candidateId",
    candidateId
  );
};

export function formatVacancyViewLink(
  vacancyId: number | string,
  vacancyName: string
) {
  return getRouteWithSlash(AppRoute.VACANCY_VIEW).replace(
    ":vacancyId",
    String(vacancyId) + "-" + stringToSlug(vacancyName)
  );
}

export function formatJobViewLink(jobId: number | string) {
  return getRouteWithSlash(AppRoute.JOB_VIEW).replace(":jobId", String(jobId));
}

export function formatCompanyViewLink(
  companyId: number | string,
  companyName: string
) {
  return getRouteWithSlash(AppRoute.COMPANY_VIEW).replace(
    ":companyId",
    String(companyId) + "-" + stringToSlug(companyName)
  );
}

export function formatReferralLink(referralCode?: string) {
  return (
    window.location.origin +
    getRouteWithSlash(AppRoute.HOME) +
    (referralCode ? "?referralCode=" + encodeURIComponent(referralCode) : "")
  );
}

export function formatVacancyReferralLink(
  vacancyId: string,
  referralCode: string
) {
  const url = AppRoute.REFERRAL_VACANCY_VIEW.replace(
    ":vacancyId",
    vacancyId
  ).replace(":referralCode", encodeURIComponent(referralCode));

  return window.location.origin + getRouteWithSlash(AppRoute.HOME) + url;
}

export function getLocality(): Locality | undefined {
  const locality = new RegExp("\\/(.*?)\\/").exec(location.pathname);

  if (!locality) {
    return undefined;
  }

  const localityCode = locality[1] as Locality;

  return ALLOWED_LOCALITIES.includes(localityCode) ? localityCode : undefined;
}

export function replacePathnameLocality(pathname: string, newLocality: string) {
  const locality = getLocality();

  if (locality) {
    return pathname.replace(locality, newLocality);
  }

  return "/" + newLocality + pathname;
}

export function getLocalitiesPaths(route: string): string[] {
  const paths = ALLOWED_LOCALITIES.map((locality) => `/${locality}` + route);

  paths.push(route);

  return paths;
}

export function isValidLocality(locality?: Locality) {
  return locality ? ALLOWED_LOCALITIES.includes(locality) : false;
}

export const getFaqLink = () => {
  const locality = getLocality();

  return locality === "ua"
    ? process.env.REACT_APP_FAQ_UA_URL
    : process.env.REACT_APP_FAQ_EN_URL;
};

export const formatLink = (link: string) => {
  const regex = /^(http:\/\/|https:\/\/)/;
  if (regex.test(link)) {
    return link;
  }

  return "https://" + link;
};
