import React, { FC } from "react";

import Props from "./Props";
import { useStyles } from "./styles";
import clsx from "clsx";

const Flex: FC<Props> = (props: Props) => {
  const {
    direction,
    children,
    className,
    alignVertical,
    alignHorizontal,
    flexGap,
    ...rest
  } = props;
  const { classes } = useStyles();

  const additionalStyles: Record<string, string | number> = {};

  if (direction) {
    additionalStyles["flexDirection"] = direction;
  }

  if (alignVertical) {
    if (direction === "column" || direction === "column-reverse") {
      additionalStyles["justifyContent"] = alignVertical;
    } else {
      additionalStyles["alignItems"] = alignVertical;
    }
  }

  if (alignHorizontal) {
    if (direction === "column" || direction === "column-reverse") {
      additionalStyles["alignItems"] = alignHorizontal;
    } else {
      additionalStyles["justifyContent"] = alignHorizontal;
    }
  }

  if (flexGap) {
    additionalStyles.gap = `${flexGap}px`;
  }

  return (
    <div
      className={clsx(classes.root, className)}
      style={additionalStyles}
      {...rest}>
      {children}
    </div>
  );
};

export default Flex;
