import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
  },
  title: {
    marginTop: theme.spacing(3),
    padding: theme.spacing(1),
    textAlign: "center",
    color: "#fff",
    background: "#458CFF",
    borderRadius: "10px",
    fontWeight: 600,
  },
  message: {
    marginTop: 16,
  },
  withMask: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    zIndex: 10,
    backgroundColor: "rgba(255,255,255, .5)",
  },
  fullScreen: {
    position: "fixed",
  },
  circularProgress: {
    height: 40,
    width: 40,
  },
}));
