import MuiChip from "./MuiChip";
import MuiButton from "./MuiButton";
import MuiDialogActions from "./MuiDialogActions";
import MuiTextField from "./MuiTextField";
import MuiTextFieldProps from "../props/MuiTextField";
import MuiChipProps from "../props/MuiChip";
import MuiSwitchProps from "../props/MuiSwitch";
import MuiInput from "./MuiInput";
import MuiTypography from "./MuiTypography";
import MuiTabs from "./MuiTabs";
import MuiTab from "./MuiTab";
import MuiTableRow from "./MuiTableRow";
import MuiTableCell from "./MuiTableCell";
import MuiSelect from "./MuiSelect";
import MuiAutocomplete from "./MuiAutocomplete";
import MuiSwitch from "./MuiSwitch";
import MuiTable from "./MuiTable";
import MuiGrid from "./MuiGrid";
import MuiSlider from "./MuiSlider";
import MuiTablePagination from "./MuiTablePagination";

const components = {
  MuiTablePagination: {
    styleOverrides: MuiTablePagination,
  },
  MuiChip: {
    styleOverrides: MuiChip,
    defaultProps: MuiChipProps,
  },
  MuiDialogActions: {
    styleOverrides: MuiDialogActions,
  },
  MuiTextField: {
    styleOverrides: MuiTextField,
    defaultProps: MuiTextFieldProps,
  },
  MuiSelect: {
    styleOverrides: MuiSelect,
  },
  MuiAutocomplete: {
    styleOverrides: MuiAutocomplete,
  },
  MuiButton: {
    styleOverrides: MuiButton,
  },
  MuiInput: {
    styleOverrides: MuiInput,
  },
  MuiTabs: {
    styleOverrides: MuiTabs,
  },
  MuiTab: {
    styleOverrides: MuiTab,
  },
  MuiTableRow: {
    styleOverrides: MuiTableRow,
  },
  MuiTable: {
    styleOverrides: MuiTable,
  },
  MuiTableCell: {
    styleOverrides: MuiTableCell,
  },
  MuiSwitch: {
    styleOverrides: MuiSwitch,
    defaultProps: MuiSwitchProps,
  },
  MuiTypography: {
    styleOverrides: MuiTypography,
  },
  MuiGrid: {
    styleOverrides: MuiGrid,
  },
  MuiSlider: {
    styleOverrides: MuiSlider,
  },
};

export default components;
