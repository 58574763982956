import React, { Dispatch, FC, SetStateAction, useState } from "react";
import { RootStorage } from "@/store/models/RootStorage";
import { Role } from "@/generated/types";

interface Props {
  children?: React.ReactElement;
}

export const defaultStorageState: RootStorage = {
  auth: {
    role: Role.Recruiter,
  },
};

interface AsyncStorageContextInterface {
  storage: RootStorage;
  setStorage: Dispatch<SetStateAction<RootStorage>>;
}

export const AsyncStorageContext =
  React.createContext<AsyncStorageContextInterface>({
    storage: defaultStorageState,
    setStorage: () => undefined,
  });

const AsyncStorageContextProvider: FC<Props> = ({ children }) => {
  const item = localStorage.getItem("root");
  const [storage, setStorage] = useState<RootStorage>(
    item ? JSON.parse(item) : defaultStorageState
  );

  return (
    <AsyncStorageContext.Provider value={{ storage, setStorage }}>
      {children}
    </AsyncStorageContext.Provider>
  );
};

export default AsyncStorageContextProvider;
