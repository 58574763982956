import { RootStorage } from "./models/RootStorage";
import { defaultStorageState } from "./components/AsyncStorageContextProvider";
import { AuthStorage } from "./models/AuthStorage";

export async function getStorageItem<T>(key: string): Promise<T> {
  const item = localStorage.getItem(key);

  return item ? JSON.parse(item) : null;
}

export const setStorageItem = async (
  key: string,
  value: Record<string, any>
) => {
  localStorage.setItem(key, JSON.stringify(value));
};

const promisesQueue: AuthStorage[] = [];

function refresh() {
  getStorageItem<RootStorage>("root").then((cachedStorage) => {
    let storage = cachedStorage || defaultStorageState;
    const authState = promisesQueue[0];

    storage = {
      auth: authState,
    };

    setStorageItem("root", storage)
      .then()
      .catch()
      .finally(() => {
        promisesQueue.shift();

        if (promisesQueue.length) {
          refresh();
        }
      });
  });
}

export function updateStorage(authState: AuthStorage) {
  const prevQueue = [...promisesQueue];
  promisesQueue.push(authState);

  if (prevQueue.length) {
    return;
  } else {
    refresh();
  }
}
