import { ComponentsOverrides, Theme } from "@mui/material";

const MuiTypography: ComponentsOverrides<Theme>["MuiTypography"] = {
  root: {
    "&.MuiLink-underlineAlways": {
      textDecoration: "none",
    },
  },
};

export default MuiTypography;
