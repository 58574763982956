export enum RecommendationStatus {
  NEW = "NEW",
  WAITING = "WAITING",
  REVIEW = "REVIEW",
  REJECTED = "REJECTED",
  PRE_SCREENING = "PRE_SCREENING",
  ARRANGING_INTERVIEW = "ARRANGING_INTERVIEW",
  INTERVIEW = "INTERVIEW",
  FINAL_INTERVIEW = "FINAL_INTERVIEW",
  TEST_TASK = "TEST_TASK",
  OFFER = "OFFER",
  HIRED = "HIRED",
}
