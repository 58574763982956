export enum Seniority {
  JUN = "Junior",
  JUN_MID = "Junior-Middle",
  MID = "Middle",
  MIN_SEN = "Middle-Senior",
  SEN = "Senior",
  LEAD = "Team/Tech Lead",
  ARCH = "Architect",
  C_LEVEL = "C-level",
  NOT_SPECIFIED = "Not specified",
}
