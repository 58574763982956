import { FC } from "react";
import ContentLoader from "react-content-loader";

export const HomePhoneLoader: FC = () => (
  <ContentLoader
    speed={2}
    width={"100%"}
    height={"100%"}
    viewBox="0 0 407 1575"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">
    <path d="M 16 0 h 375 v 40 H 16 z" />
    <rect x="16" y="60" rx="20" ry="20" width="375" height="284" />
    <rect x="16" y="364" rx="20" ry="20" width="375" height="324" />
    <path d="M 306 778 H 101 v 44 h 205 z" />
    <rect x="16" y="862" rx="6" ry="6" width="375" height="194" />
    <rect x="16" y="1072" rx="6" ry="6" width="375" height="194" />
    <rect x="16" y="1282" rx="6" ry="6" width="375" height="194" />
  </ContentLoader>
);
