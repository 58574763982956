import { FC } from "react";
import ContentLoader from "react-content-loader";

export const CandidatesDesktopLoader: FC = () => (
  <ContentLoader
    speed={2}
    width={1459}
    height={1242}
    style={{ margin: "auto" }}
    viewBox="0 0 1459 1242"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">
    <path d="M 16 14 h 1403 v 43 H 16 z" />
    <rect x="681" y="689" rx="15" ry="15" width="357" height="509" />
    <rect x="300" y="689" rx="15" ry="15" width="357" height="509" />
    <rect x="1062" y="156" rx="15" ry="15" width="357" height="509" />
    <rect x="681" y="156" rx="15" ry="15" width="357" height="509" />
    <rect x="300" y="156" rx="15" ry="15" width="357" height="509" />
    <path d="M 16 71 h 260 v 519 H 16 z M 300 77.09 h 1119 v 55 H 300 z" />
    <rect x="1062" y="689" rx="15" ry="15" width="357" height="509" />
  </ContentLoader>
);
