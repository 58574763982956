import { ComponentsOverrides, Theme } from "@mui/material";

const MuiInput: ComponentsOverrides<Theme>["MuiInput"] = {
  root: {
    borderRadius: 4,
  },
  input: {
    "&::placeholder": {
      fontSize: 14,
    },
  },
  underline: {
    "&::before": {
      borderColor: "#E4E7EF",
    },
    "&:hover:not($disabled):before": {
      borderBottom: "1px solid #C1C7CD",
    },
  },
  focused: {},
};

export default MuiInput;
