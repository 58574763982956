import { useRef, useEffect } from "react";
import { useSearchParams } from "./useSearchParams";
import Cookies from "js-cookie";
import { CookieKeys } from "../models/CookieKeys";
import { SearchKeys } from "../models/SearchKeys";

export const useSearchTracking = () => {
  const { getSearchParams } = useSearchParams();
  const isMounted = useRef(false);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    const newUserFrom = getSearchParams(SearchKeys.REFERENCE);

    if (newUserFrom) {
      const url = window.location.href;

      Cookies.set(CookieKeys.NEW_USER, newUserFrom, { expires: 3 });
      Cookies.set(CookieKeys.METADATA, url, { expires: 3 });
    }

    isMounted.current = true;
  }, [getSearchParams]);

  return true;
};
