import { ComponentsOverrides, Theme } from "@mui/material";

const MuiTablePagination: ComponentsOverrides<Theme>["MuiTablePagination"] = {
  root: {
    width: "100%",
  },
  toolbar: {
    flexWrap: "wrap",
    justifyContent: "center",

    "@media (max-width: 600px)": {
      width: "240px",
      margin: "auto",
    },
    "@media (min-width: 300px)": {
      paddingLeft: "20px",
    },
    "@media (min-width: 600px)": {
      paddingLeft: 0,
    },
  },
  spacer: {
    display: "none",
  },
  selectRoot: {
    "@media (max-width: 600px)": {
      marginRight: "12px",
    },
  },
  displayedRows: {
    "@media (min-width: 1200px)": {
      marginLeft: "auto",
    },
  },
};

export default MuiTablePagination;
