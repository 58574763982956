import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
import {
  ChatPreviewFragmentDoc,
  MessageFragmentDoc,
  FullChatFragmentDoc,
} from "./fragments.generated";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ChatsQueryVariables = Types.Exact<{
  plan?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type ChatsQuery = {
  __typename?: "Query";
  chats: Array<{
    __typename?: "Chat";
    id: string;
    name?: string | null;
    newQty: number;
    picture?: string | null;
    lastMessage?: {
      __typename?: "ChatMessage";
      id: string;
      createdAt: any;
      content: string;
      isNew: boolean;
      isMine: boolean;
      error?: boolean | null;
      recommendation?: {
        __typename?: "Recommendation";
        name?: string | null;
        emailCandidate?: string | null;
        recommendatorEmail?: string | null;
        vacancy: { __typename?: "Vacancy"; name: string; id: string };
      } | null;
      candidate?: {
        __typename?: "Candidate";
        id: string;
        position?: string | null;
      } | null;
      vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
      chat?: { __typename?: "Chat"; id: string } | null;
    } | null;
  }>;
};

export type RemoveChatMutationVariables = Types.Exact<{
  chatId: Types.Scalars["ID"];
}>;

export type RemoveChatMutation = {
  __typename?: "Mutation";
  removeChat: string;
};

export type AddMessageMutationVariables = Types.Exact<{
  chatId: Types.Scalars["ID"];
  message?: Types.InputMaybe<Types.MessageInput>;
  content?: Types.InputMaybe<Types.Scalars["String"]>;
  recommendationId?: Types.InputMaybe<Types.Scalars["ID"]>;
  vacancyId?: Types.InputMaybe<Types.Scalars["ID"]>;
  candidateId?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type AddMessageMutation = {
  __typename?: "Mutation";
  addMessage: {
    __typename?: "ChatMessage";
    id: string;
    createdAt: any;
    content: string;
    isNew: boolean;
    isMine: boolean;
    error?: boolean | null;
    recommendation?: {
      __typename?: "Recommendation";
      name?: string | null;
      emailCandidate?: string | null;
      recommendatorEmail?: string | null;
      vacancy: { __typename?: "Vacancy"; name: string; id: string };
    } | null;
    candidate?: {
      __typename?: "Candidate";
      id: string;
      position?: string | null;
    } | null;
    vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
    chat?: { __typename?: "Chat"; id: string } | null;
  };
};

export type AddMessageAdminMutationVariables = Types.Exact<{
  chatId: Types.Scalars["ID"];
  content?: Types.InputMaybe<Types.Scalars["String"]>;
  message?: Types.InputMaybe<Types.MessageInput>;
  recommendationId?: Types.InputMaybe<Types.Scalars["ID"]>;
  vacancyId?: Types.InputMaybe<Types.Scalars["ID"]>;
  candidateId?: Types.InputMaybe<Types.Scalars["ID"]>;
}>;

export type AddMessageAdminMutation = {
  __typename?: "Mutation";
  addMessageAdmin: {
    __typename?: "ChatMessage";
    id: string;
    createdAt: any;
    content: string;
    isNew: boolean;
    isMine: boolean;
    error?: boolean | null;
    recommendation?: {
      __typename?: "Recommendation";
      name?: string | null;
      emailCandidate?: string | null;
      recommendatorEmail?: string | null;
      vacancy: { __typename?: "Vacancy"; name: string; id: string };
    } | null;
    candidate?: {
      __typename?: "Candidate";
      id: string;
      position?: string | null;
    } | null;
    vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
    chat?: { __typename?: "Chat"; id: string } | null;
  };
};

export type ChatQueryVariables = Types.Exact<{
  chatId: Types.Scalars["ID"];
}>;

export type ChatQuery = {
  __typename?: "Query";
  chat: {
    __typename?: "Chat";
    id: string;
    name?: string | null;
    picture?: string | null;
    lastMessage?: {
      __typename?: "ChatMessage";
      id: string;
      createdAt: any;
      content: string;
      isNew: boolean;
      isMine: boolean;
      error?: boolean | null;
      recommendation?: {
        __typename?: "Recommendation";
        name?: string | null;
        emailCandidate?: string | null;
        recommendatorEmail?: string | null;
        vacancy: { __typename?: "Vacancy"; name: string; id: string };
      } | null;
      candidate?: {
        __typename?: "Candidate";
        id: string;
        position?: string | null;
      } | null;
      vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
      chat?: { __typename?: "Chat"; id: string } | null;
    } | null;
    messages: Array<{
      __typename?: "ChatMessage";
      id: string;
      createdAt: any;
      content: string;
      isNew: boolean;
      isMine: boolean;
      error?: boolean | null;
      recommendation?: {
        __typename?: "Recommendation";
        name?: string | null;
        emailCandidate?: string | null;
        recommendatorEmail?: string | null;
        vacancy: { __typename?: "Vacancy"; name: string; id: string };
      } | null;
      candidate?: {
        __typename?: "Candidate";
        id: string;
        position?: string | null;
      } | null;
      vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
      chat?: { __typename?: "Chat"; id: string } | null;
    }>;
  };
};

export type NewChatMessageSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type NewChatMessageSubscription = {
  __typename?: "Subscription";
  newChatMessage: {
    __typename?: "ChatMessage";
    id: string;
    createdAt: any;
    content: string;
    isNew: boolean;
    isMine: boolean;
    error?: boolean | null;
    recommendation?: {
      __typename?: "Recommendation";
      name?: string | null;
      emailCandidate?: string | null;
      recommendatorEmail?: string | null;
      vacancy: { __typename?: "Vacancy"; name: string; id: string };
    } | null;
    candidate?: {
      __typename?: "Candidate";
      id: string;
      position?: string | null;
    } | null;
    vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
    chat?: { __typename?: "Chat"; id: string } | null;
  };
};

export type MarkMessageAsReadMutationVariables = Types.Exact<{
  messageId: Types.Scalars["ID"];
}>;

export type MarkMessageAsReadMutation = {
  __typename?: "Mutation";
  markMessageAsRead: { __typename?: "ChatMessage"; id: string };
};

export type ViewMessageSubscriptionVariables = Types.Exact<{
  [key: string]: never;
}>;

export type ViewMessageSubscription = {
  __typename?: "Subscription";
  viewMessage: {
    __typename?: "ChatMessage";
    id: string;
    createdAt: any;
    content: string;
    isNew: boolean;
    isMine: boolean;
    error?: boolean | null;
    recommendation?: {
      __typename?: "Recommendation";
      name?: string | null;
      emailCandidate?: string | null;
      recommendatorEmail?: string | null;
      vacancy: { __typename?: "Vacancy"; name: string; id: string };
    } | null;
    candidate?: {
      __typename?: "Candidate";
      id: string;
      position?: string | null;
    } | null;
    vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
    chat?: { __typename?: "Chat"; id: string } | null;
  };
};

export const ChatsDocument = gql`
  query Chats($plan: String) {
    chats(plan: $plan) {
      ...ChatPreview
    }
  }
  ${ChatPreviewFragmentDoc}
`;

/**
 * __useChatsQuery__
 *
 * To run a query within a React component, call `useChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatsQuery({
 *   variables: {
 *      plan: // value for 'plan'
 *   },
 * });
 */
export function useChatsQuery(
  baseOptions?: Apollo.QueryHookOptions<ChatsQuery, ChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsDocument,
    options
  );
}
export function useChatsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatsQuery, ChatsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatsQuery, ChatsQueryVariables>(
    ChatsDocument,
    options
  );
}
export type ChatsQueryHookResult = ReturnType<typeof useChatsQuery>;
export type ChatsLazyQueryHookResult = ReturnType<typeof useChatsLazyQuery>;
export type ChatsQueryResult = Apollo.QueryResult<
  ChatsQuery,
  ChatsQueryVariables
>;
export const RemoveChatDocument = gql`
  mutation RemoveChat($chatId: ID!) {
    removeChat(chatId: $chatId)
  }
`;
export type RemoveChatMutationFn = Apollo.MutationFunction<
  RemoveChatMutation,
  RemoveChatMutationVariables
>;

/**
 * __useRemoveChatMutation__
 *
 * To run a mutation, you first call `useRemoveChatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveChatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeChatMutation, { data, loading, error }] = useRemoveChatMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useRemoveChatMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RemoveChatMutation,
    RemoveChatMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveChatMutation, RemoveChatMutationVariables>(
    RemoveChatDocument,
    options
  );
}
export type RemoveChatMutationHookResult = ReturnType<
  typeof useRemoveChatMutation
>;
export type RemoveChatMutationResult =
  Apollo.MutationResult<RemoveChatMutation>;
export type RemoveChatMutationOptions = Apollo.BaseMutationOptions<
  RemoveChatMutation,
  RemoveChatMutationVariables
>;
export const AddMessageDocument = gql`
  mutation AddMessage(
    $chatId: ID!
    $message: MessageInput
    $content: String
    $recommendationId: ID
    $vacancyId: ID
    $candidateId: ID
  ) {
    addMessage(
      chatId: $chatId
      content: $content
      message: $message
      recommendationId: $recommendationId
      vacancyId: $vacancyId
      candidateId: $candidateId
    ) {
      ...Message
    }
  }
  ${MessageFragmentDoc}
`;
export type AddMessageMutationFn = Apollo.MutationFunction<
  AddMessageMutation,
  AddMessageMutationVariables
>;

/**
 * __useAddMessageMutation__
 *
 * To run a mutation, you first call `useAddMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageMutation, { data, loading, error }] = useAddMessageMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      message: // value for 'message'
 *      content: // value for 'content'
 *      recommendationId: // value for 'recommendationId'
 *      vacancyId: // value for 'vacancyId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useAddMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMessageMutation,
    AddMessageMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddMessageMutation, AddMessageMutationVariables>(
    AddMessageDocument,
    options
  );
}
export type AddMessageMutationHookResult = ReturnType<
  typeof useAddMessageMutation
>;
export type AddMessageMutationResult =
  Apollo.MutationResult<AddMessageMutation>;
export type AddMessageMutationOptions = Apollo.BaseMutationOptions<
  AddMessageMutation,
  AddMessageMutationVariables
>;
export const AddMessageAdminDocument = gql`
  mutation AddMessageAdmin(
    $chatId: ID!
    $content: String
    $message: MessageInput
    $recommendationId: ID
    $vacancyId: ID
    $candidateId: ID
  ) {
    addMessageAdmin(
      chatId: $chatId
      content: $content
      message: $message
      recommendationId: $recommendationId
      vacancyId: $vacancyId
      candidateId: $candidateId
    ) {
      ...Message
    }
  }
  ${MessageFragmentDoc}
`;
export type AddMessageAdminMutationFn = Apollo.MutationFunction<
  AddMessageAdminMutation,
  AddMessageAdminMutationVariables
>;

/**
 * __useAddMessageAdminMutation__
 *
 * To run a mutation, you first call `useAddMessageAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddMessageAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addMessageAdminMutation, { data, loading, error }] = useAddMessageAdminMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      content: // value for 'content'
 *      message: // value for 'message'
 *      recommendationId: // value for 'recommendationId'
 *      vacancyId: // value for 'vacancyId'
 *      candidateId: // value for 'candidateId'
 *   },
 * });
 */
export function useAddMessageAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<
    AddMessageAdminMutation,
    AddMessageAdminMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    AddMessageAdminMutation,
    AddMessageAdminMutationVariables
  >(AddMessageAdminDocument, options);
}
export type AddMessageAdminMutationHookResult = ReturnType<
  typeof useAddMessageAdminMutation
>;
export type AddMessageAdminMutationResult =
  Apollo.MutationResult<AddMessageAdminMutation>;
export type AddMessageAdminMutationOptions = Apollo.BaseMutationOptions<
  AddMessageAdminMutation,
  AddMessageAdminMutationVariables
>;
export const ChatDocument = gql`
  query Chat($chatId: ID!) {
    chat(chatId: $chatId) {
      ...FullChat
    }
  }
  ${FullChatFragmentDoc}
`;

/**
 * __useChatQuery__
 *
 * To run a query within a React component, call `useChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChatQuery({
 *   variables: {
 *      chatId: // value for 'chatId'
 *   },
 * });
 */
export function useChatQuery(
  baseOptions: Apollo.QueryHookOptions<ChatQuery, ChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ChatQuery, ChatQueryVariables>(ChatDocument, options);
}
export function useChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ChatQuery, ChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ChatQuery, ChatQueryVariables>(
    ChatDocument,
    options
  );
}
export type ChatQueryHookResult = ReturnType<typeof useChatQuery>;
export type ChatLazyQueryHookResult = ReturnType<typeof useChatLazyQuery>;
export type ChatQueryResult = Apollo.QueryResult<ChatQuery, ChatQueryVariables>;
export const NewChatMessageDocument = gql`
  subscription NewChatMessage {
    newChatMessage {
      ...Message
    }
  }
  ${MessageFragmentDoc}
`;

/**
 * __useNewChatMessageSubscription__
 *
 * To run a query within a React component, call `useNewChatMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useNewChatMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChatMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useNewChatMessageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    NewChatMessageSubscription,
    NewChatMessageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    NewChatMessageSubscription,
    NewChatMessageSubscriptionVariables
  >(NewChatMessageDocument, options);
}
export type NewChatMessageSubscriptionHookResult = ReturnType<
  typeof useNewChatMessageSubscription
>;
export type NewChatMessageSubscriptionResult =
  Apollo.SubscriptionResult<NewChatMessageSubscription>;
export const MarkMessageAsReadDocument = gql`
  mutation markMessageAsRead($messageId: ID!) {
    markMessageAsRead(messageId: $messageId) {
      id
    }
  }
`;
export type MarkMessageAsReadMutationFn = Apollo.MutationFunction<
  MarkMessageAsReadMutation,
  MarkMessageAsReadMutationVariables
>;

/**
 * __useMarkMessageAsReadMutation__
 *
 * To run a mutation, you first call `useMarkMessageAsReadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkMessageAsReadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markMessageAsReadMutation, { data, loading, error }] = useMarkMessageAsReadMutation({
 *   variables: {
 *      messageId: // value for 'messageId'
 *   },
 * });
 */
export function useMarkMessageAsReadMutation(
  baseOptions?: Apollo.MutationHookOptions<
    MarkMessageAsReadMutation,
    MarkMessageAsReadMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    MarkMessageAsReadMutation,
    MarkMessageAsReadMutationVariables
  >(MarkMessageAsReadDocument, options);
}
export type MarkMessageAsReadMutationHookResult = ReturnType<
  typeof useMarkMessageAsReadMutation
>;
export type MarkMessageAsReadMutationResult =
  Apollo.MutationResult<MarkMessageAsReadMutation>;
export type MarkMessageAsReadMutationOptions = Apollo.BaseMutationOptions<
  MarkMessageAsReadMutation,
  MarkMessageAsReadMutationVariables
>;
export const ViewMessageDocument = gql`
  subscription ViewMessage {
    viewMessage {
      ...Message
    }
  }
  ${MessageFragmentDoc}
`;

/**
 * __useViewMessageSubscription__
 *
 * To run a query within a React component, call `useViewMessageSubscription` and pass it any options that fit your needs.
 * When your component renders, `useViewMessageSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewMessageSubscription({
 *   variables: {
 *   },
 * });
 */
export function useViewMessageSubscription(
  baseOptions?: Apollo.SubscriptionHookOptions<
    ViewMessageSubscription,
    ViewMessageSubscriptionVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSubscription<
    ViewMessageSubscription,
    ViewMessageSubscriptionVariables
  >(ViewMessageDocument, options);
}
export type ViewMessageSubscriptionHookResult = ReturnType<
  typeof useViewMessageSubscription
>;
export type ViewMessageSubscriptionResult =
  Apollo.SubscriptionResult<ViewMessageSubscription>;
