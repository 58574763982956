interface GTMBaseEvent {
  event: string;
}

declare global {
  interface Window {
    dataLayer: GTMBaseEvent[];
  }
}

export function pushGTMEvent<T extends GTMBaseEvent>(event: T) {
  if (!window.dataLayer) {
    window.dataLayer = [event];
  } else {
    window.dataLayer.push(event);
  }
}
