import { ComponentsOverrides, Theme } from "@mui/material";

const MuiSwitch: ComponentsOverrides<Theme>["MuiSwitch"] = {
  root: {
    width: 42,
    height: 26,
    padding: 0,
    margin: "8px",
  },
  switchBase: {
    padding: 1,
    "&.Mui-checked": {
      transform: "translateX(16px) !important",
      color: "#FFFFFF !important",
      "& + .MuiSwitch-track": {
        backgroundColor: "#458CFF",
        opacity: 1,
        border: "none",
      },
    },
    "&.Mui-disabled.Mui-checked": {
      "& + .Mui-track": {
        backgroundColor: "#458CFF",
        opacity: 0.7,
        border: "none",
      },
    },
    "&.Mui-focus-visible .Mui-thumb": {
      color: "#52d869",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 24,
    height: 24,
  },
  track: {
    borderRadius: 26 / 2,
    border: `1px solid #e0e0e0`,
    backgroundColor: "#fafafa",
    opacity: 1,
    transition:
      "background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
  },
};

export default MuiSwitch;
