import { FC } from "react";
import ContentLoader from "react-content-loader";

export const CompaniesPhoneLoader: FC = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height="100%"
    viewBox="0 0 372 840"
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb">
    <path d="M 16 10.565 h 340 v 34 H 16 z" />
    <rect x="16" y="644" rx="6" ry="6" width="340" height="178" />
    <rect x="16" y="450" rx="6" ry="6" width="340" height="178" />
    <rect x="16" y="256" rx="6" ry="6" width="340" height="178" />
    <rect x="16" y="127" rx="4" ry="4" width="340" height="47" />
    <rect x="16" y="185" rx="4" ry="4" width="340" height="42" />
    <rect x="16" y="69" rx="4" ry="4" width="340" height="47" />
  </ContentLoader>
);
