import React, { FC, PropsWithChildren } from "react";
import { CompaniesGridFilterContext } from "./CompaniesGridFilterContext";

import { defaultCompaniesGridFilter } from "./defaultCompaniesGridFilter";
import { useFilter } from "../../../Filter/useFilter";

const CompaniesGridFilterContextProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const context = useFilter(defaultCompaniesGridFilter);

  return (
    <CompaniesGridFilterContext.Provider value={context}>
      {children}
    </CompaniesGridFilterContext.Provider>
  );
};

export default CompaniesGridFilterContextProvider;
