import { makeStyles } from "tss-react/mui";
import { AppTheme } from "@/theme";

export const useStyles = makeStyles()((theme: AppTheme) => ({
  root: {
    color: "rgba(0, 0, 0, 0.87)",
    backgroundColor: "#fff",
    borderRadius: 6,
    boxShadow: "0px 2px 16px rgba(121, 121, 121, 0.12)",
  },
}));
