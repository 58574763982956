import { VacanciesFilter } from "./models/VacanciesFilter";
import {
  BUDGET_MAX,
  BUDGET_MIN,
  EXPERIENCE_MAX,
  EXPERIENCE_MIN,
} from "../../../../../config";
import { VacancyOrderType } from "../../../../models/VacancyOrderType";

export const defaultVacanciesFilter: VacanciesFilter = {
  query: "",
  status: [],
  order: VacancyOrderType.BONUS,
  skill: [],
  paidPlan: [],
  city: [],
  company: [],
  englishLevel: [],
  seniority: [],
  budget: {
    max: BUDGET_MAX,
    min: BUDGET_MIN,
  },
  experience: {
    max: EXPERIENCE_MAX,
    min: EXPERIENCE_MIN,
  },
  minEnglishLevel: 0,
};
