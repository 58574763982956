import { PropsWithChildren } from "react";
import { RecommendationStatus } from "../../models/RecommendationStatus";

export default interface Props extends PropsWithChildren<any> {
  status: RecommendationStatus;
  isAiRejected: boolean;
}

export const excludedStatusesForPendingTab = [
  RecommendationStatus.REJECTED,
  RecommendationStatus.HIRED,
];

export const excludedStatusesForRejectedTab = [
  RecommendationStatus.NEW,
  RecommendationStatus.WAITING,
  RecommendationStatus.REVIEW,
  RecommendationStatus.PRE_SCREENING,
  RecommendationStatus.ARRANGING_INTERVIEW,
  RecommendationStatus.INTERVIEW,
  RecommendationStatus.FINAL_INTERVIEW,
  RecommendationStatus.TEST_TASK,
  RecommendationStatus.OFFER,
  RecommendationStatus.HIRED,
];

export const excludedStatusesForHiredTab = [
  RecommendationStatus.NEW,
  RecommendationStatus.WAITING,
  RecommendationStatus.REVIEW,
  RecommendationStatus.PRE_SCREENING,
  RecommendationStatus.ARRANGING_INTERVIEW,
  RecommendationStatus.INTERVIEW,
  RecommendationStatus.FINAL_INTERVIEW,
  RecommendationStatus.TEST_TASK,
  RecommendationStatus.OFFER,
  RecommendationStatus.REJECTED,
];

