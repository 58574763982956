import { useIsAdmin } from "@/auth/hooks/useIsAdmin";
import { useIsCompany } from "@/auth/hooks/useIsCompany";
import { useIsRecommender } from "@/auth/hooks/useIsRecommender";

export function useIsChatUser() {
  const isAdmin = useIsAdmin();
  const isCompany = useIsCompany();
  const isRecommender = useIsRecommender();

  return isAdmin || isCompany || isRecommender;
}
