import { CompaniesOrder } from "@/generated/types";
import { CompaniesGridFilter } from "./models/CompaniesGridFilter";

export const LIMIT_COMPANIES = 15;

export const defaultCompaniesGridFilter: CompaniesGridFilter = {
  query: "",
  order: CompaniesOrder.Plan,
  offset: 0,
  limit: LIMIT_COMPANIES,
};
