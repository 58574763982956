import { useContext, useEffect, useState } from "react";
import { AsyncStorageContext } from "../../store/components/AsyncStorageContextProvider";
import { updateStorage } from "../../store/asyncStorageUtils";

export function useCachedState() {
  const { storage, setStorage } = useContext(AsyncStorageContext);
  const [authState, setAuthState] = useState(storage.auth);

  useEffect(() => {
    updateStorage(authState);
    setStorage({
      auth: authState,
    });
  }, [setStorage, authState]);

  return [authState, setAuthState] as const;
}
