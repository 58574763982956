const chat = Object.freeze({
  notificationTitle: (chatName?: string) =>
    chatName ? `New message from ${chatName}` : "New message",
  textInputPlaceholder: "Type a message",
  deleteChatMessage:
    "Do you really want to completely delete this chat? All conversations will be lost permanently",
  emptyChatTitle: "There are no conversations yet",
  chatNotExist: "The chat does not exist or has been deleted",
  selectChat: "Please select a conversation to start messaging",
  emptyChatSubtitle:
    "The history of your dialogues will be stored here. Wait for incoming messages or write someone first",
  goHome: "Go to the homepage",
  messageTitle: "Regarding:",
  messageTitleCandidate: "Regarding application",
  messageRecommendation: "Recommendation ",
  messageVacancy: "Vacancy ",
  messageOnVacancy: "On vacancy ",
  messageCandidate: "Candidate ",
});

export default chat;

export type DChatType = Record<keyof typeof chat, any>;
