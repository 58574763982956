import {
  HideAlertActionType,
  ShowAlertActionType,
} from "../components/Alert/components/AlertsContainer.types";

const showPushNotificationDialogEvent = new CustomEvent(
  "showPushNotificationDialog"
);

const showNewUpdatesDialogEvent = new CustomEvent("showNewUpdatesDialog");
const showNewUpdatesEvent = new CustomEvent("showNewUpdates");

export const showPushNotificationDialog = () => {
  document.dispatchEvent(showPushNotificationDialogEvent);
};

export const showNewUpdates = () => {
  document.dispatchEvent(showNewUpdatesEvent);
};

export const showNewUpdatesDialog = () => {
  document.dispatchEvent(showNewUpdatesDialogEvent);
};

export const showAlert: ShowAlertActionType = (
  message,
  severity,
  title?,
  onClick?
) => {
  const showAlertEvent = new CustomEvent("showAlert", {
    detail: { message, severity, title, onClick },
  });

  window.dispatchEvent(showAlertEvent);
};

export const hideAlert: HideAlertActionType = (alertId) => {
  const hideAlertEvent = new CustomEvent("hideAlert", {
    detail: alertId,
  });

  window.dispatchEvent(hideAlertEvent);
};
