const chat = Object.freeze({
  notificationTitle: (chatName?: string) =>
    chatName ? `Нове повідомлення від ${chatName}` : "Нове повідомлення",
  textInputPlaceholder: "Введіть повідомлення",
  deleteChatMessage:
    "Ви дійсно хочете повністю видалити цей чат? Усі розмови буде втрачено назавжди",
  chatNotExist: "Чат не існує або був видалений",
  emptyChatTitle: "Активні бесіди відсутні",
  selectChat: "Будь ласка, оберіть бесіду щоб почати спілкування",
  emptyChatSubtitle:
    "Тут буде зберігатися історія ваших діалогів. Очікуйте на вхідні повідомлення або напишіть комусь першими",
  goHome: "Повернутися на головну",
  messageTitle: "Стосовно:",
  messageTitleCandidate: "Стосовно відгуку",
  messageRecommendation: "Рекомендації ",
  messageVacancy: "Вакансії ",
  messageOnVacancy: "На вакансію ",
  messageCandidate: "Кандидата ",
});

export default chat;
