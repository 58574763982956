import React, { FC } from "react";

const PageLoader: FC = () => {
  return (
    <div id="loading">
      <div className="loader">
        <div className="loader__row">
          <div className="loader__arrow up inner inner-6" />
          <div className="loader__arrow down inner inner-5" />
          <div className="loader__arrow up inner inner-4" />
        </div>
        <div className="loader__row">
          <div className="loader__arrow down inner inner-1" />
          <div className="loader__arrow up inner inner-2" />
          <div className="loader__arrow down inner inner-3" />
        </div>
      </div>
      <span>Page is loading...</span>
    </div>
  );
};

export default PageLoader;
