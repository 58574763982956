import { ComponentsOverrides, Theme } from "@mui/material";

const MuiSlider: ComponentsOverrides<Theme>["MuiSlider"] = {
  root: {
    height: 3,
  },
  thumb: {
    width: 15,
    height: 15,
  },
};

export default MuiSlider;
