import * as Types from "../../../../generated/types";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type NewChatMessagesCountQueryVariables = Types.Exact<{
  [key: string]: never;
}>;

export type NewChatMessagesCountQuery = {
  __typename?: "Query";
  newMessagesCount: number;
};

export const NewChatMessagesCountDocument = gql`
  query NewChatMessagesCount {
    newMessagesCount
  }
`;

/**
 * __useNewChatMessagesCountQuery__
 *
 * To run a query within a React component, call `useNewChatMessagesCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewChatMessagesCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewChatMessagesCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useNewChatMessagesCountQuery(
  baseOptions?: Apollo.QueryHookOptions<
    NewChatMessagesCountQuery,
    NewChatMessagesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    NewChatMessagesCountQuery,
    NewChatMessagesCountQueryVariables
  >(NewChatMessagesCountDocument, options);
}
export function useNewChatMessagesCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    NewChatMessagesCountQuery,
    NewChatMessagesCountQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    NewChatMessagesCountQuery,
    NewChatMessagesCountQueryVariables
  >(NewChatMessagesCountDocument, options);
}
export type NewChatMessagesCountQueryHookResult = ReturnType<
  typeof useNewChatMessagesCountQuery
>;
export type NewChatMessagesCountLazyQueryHookResult = ReturnType<
  typeof useNewChatMessagesCountLazyQuery
>;
export type NewChatMessagesCountQueryResult = Apollo.QueryResult<
  NewChatMessagesCountQuery,
  NewChatMessagesCountQueryVariables
>;
