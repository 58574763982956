import React from "react";
import { ChipProps } from "@mui/material/Chip";
import { ReactComponent as RemoveIcon } from "../../common/icons/remove.svg";
import Box from "@mui/material/Box";

const MuiChip: ChipProps = {
  deleteIcon: (
    <Box width={14} height={14}>
      <RemoveIcon />
    </Box>
  ),
};

export default MuiChip;
