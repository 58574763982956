import React, { FC, PropsWithChildren } from "react";
import { CandidatesFilterContext } from "./CandidatesFilterContext";
import { defaultCandidatesFilter } from "./defaultCandidatesFilter";
import { useFilter } from "../../../Filter/useFilter";

const CandidatesFilterContextProvider: FC<PropsWithChildren<any>> = ({
  children,
}) => {
  const context = useFilter(defaultCandidatesFilter);

  return (
    <CandidatesFilterContext.Provider value={context}>
      {children}
    </CandidatesFilterContext.Provider>
  );
};

export default CandidatesFilterContextProvider;
