import { Dictionary } from "@/i18n/dictionary.types";
import { getLocality, isValidLocality } from "@/common/utils/navUtils";
import { DEFAULT_LOCALITY } from "@/config";

const browserLocality = getLocality();

const CURRENT_LOCALE =
  browserLocality && isValidLocality(browserLocality)
    ? browserLocality
    : DEFAULT_LOCALITY;

export function t<T>(dictionary: Dictionary<T>): T {
  return dictionary[CURRENT_LOCALE];
}
