import React from "react";
import { IVacanciesFilterContext } from "./models/IVacanciesFilterContext";
import { defaultVacanciesFilter } from "./defaultVacanciesFilter";

export const VacanciesFilterContext =
  React.createContext<IVacanciesFilterContext>({
    filter: defaultVacanciesFilter,
    applyFilter: () => undefined,
    applyFilters: () => undefined,
    clearFilter: () => undefined,
    reset: () => undefined,
  });
