import * as Types from "../../generated/types";

import { gql } from "@apollo/client";
export type ChatPreviewFragment = {
  __typename?: "Chat";
  id: string;
  name?: string | null;
  newQty: number;
  picture?: string | null;
  lastMessage?: {
    __typename?: "ChatMessage";
    id: string;
    createdAt: any;
    content: string;
    isNew: boolean;
    isMine: boolean;
    error?: boolean | null;
    recommendation?: {
      __typename?: "Recommendation";
      name?: string | null;
      emailCandidate?: string | null;
      recommendatorEmail?: string | null;
      vacancy: { __typename?: "Vacancy"; name: string; id: string };
    } | null;
    candidate?: {
      __typename?: "Candidate";
      id: string;
      position?: string | null;
    } | null;
    vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
    chat?: { __typename?: "Chat"; id: string } | null;
  } | null;
};

export type CompanyIdNameFragment = {
  __typename?: "Company";
  id: string;
  name: string;
};

export type MessageFragment = {
  __typename?: "ChatMessage";
  id: string;
  createdAt: any;
  content: string;
  isNew: boolean;
  isMine: boolean;
  error?: boolean | null;
  recommendation?: {
    __typename?: "Recommendation";
    name?: string | null;
    emailCandidate?: string | null;
    recommendatorEmail?: string | null;
    vacancy: { __typename?: "Vacancy"; name: string; id: string };
  } | null;
  candidate?: {
    __typename?: "Candidate";
    id: string;
    position?: string | null;
  } | null;
  vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
  chat?: { __typename?: "Chat"; id: string } | null;
};

export type FullChatFragment = {
  __typename?: "Chat";
  id: string;
  name?: string | null;
  picture?: string | null;
  lastMessage?: {
    __typename?: "ChatMessage";
    id: string;
    createdAt: any;
    content: string;
    isNew: boolean;
    isMine: boolean;
    error?: boolean | null;
    recommendation?: {
      __typename?: "Recommendation";
      name?: string | null;
      emailCandidate?: string | null;
      recommendatorEmail?: string | null;
      vacancy: { __typename?: "Vacancy"; name: string; id: string };
    } | null;
    candidate?: {
      __typename?: "Candidate";
      id: string;
      position?: string | null;
    } | null;
    vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
    chat?: { __typename?: "Chat"; id: string } | null;
  } | null;
  messages: Array<{
    __typename?: "ChatMessage";
    id: string;
    createdAt: any;
    content: string;
    isNew: boolean;
    isMine: boolean;
    error?: boolean | null;
    recommendation?: {
      __typename?: "Recommendation";
      name?: string | null;
      emailCandidate?: string | null;
      recommendatorEmail?: string | null;
      vacancy: { __typename?: "Vacancy"; name: string; id: string };
    } | null;
    candidate?: {
      __typename?: "Candidate";
      id: string;
      position?: string | null;
    } | null;
    vacancy?: { __typename?: "Vacancy"; id: string; name: string } | null;
    chat?: { __typename?: "Chat"; id: string } | null;
  }>;
};

export const MessageFragmentDoc = gql`
  fragment Message on ChatMessage {
    id
    createdAt
    content
    isNew
    isMine
    error
    recommendation {
      name
      emailCandidate
      recommendatorEmail
      vacancy {
        name
        id
      }
    }
    candidate {
      id
      position
    }
    vacancy {
      id
      name
    }
    chat {
      id
    }
  }
`;
export const ChatPreviewFragmentDoc = gql`
  fragment ChatPreview on Chat {
    id
    name
    newQty
    picture
    lastMessage {
      ...Message
    }
  }
  ${MessageFragmentDoc}
`;
export const CompanyIdNameFragmentDoc = gql`
  fragment CompanyIdName on Company {
    id
    name
  }
`;
export const FullChatFragmentDoc = gql`
  fragment FullChat on Chat {
    id
    name
    picture
    lastMessage {
      ...Message
    }
    messages {
      ...Message
    }
  }
  ${MessageFragmentDoc}
`;
