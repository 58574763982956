import { ComponentsOverrides, Theme } from "@mui/material";

const MuiTab: ComponentsOverrides<Theme>["MuiTab"] = {
  root: {
    textTransform: "none",
    fontSize: 16,
  },
};

export default MuiTab;
