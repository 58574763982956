import { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { FilterClickable, FilterRange } from "./models/FilterType";
import { IFilterContext } from "./models/IFilterContext";
import { IFilter } from "./models/IFilter";
import { useSearchParams } from "@/common/hooks/useSearchParams";

export function useFilter<F extends IFilter>(
  defaultFilter: F
): IFilterContext<F> {
  const location = useLocation();
  const [filter, setFilter] = useState<F>(defaultFilter);
  const {
    getFilterWithSearch,
    updateSearch,
    clearSearchParams,
    getAllSearchParams,
  } = useSearchParams();
  const isMounted = useRef(false);
  const isSearchParamsLoaded = useRef(false);
  const prevLocation = useRef(location.search);

  useEffect(() => {
    if (isMounted.current) {
      return;
    }

    for (const key in defaultFilter) {
      const value = defaultFilter[key];
      const params = getAllSearchParams(key);

      if (Array.isArray(value) && value.length) {
        value.forEach((el) =>
          params.includes(JSON.stringify(el)) ? null : updateSearch(key, el)
        );
      }
    }

    isMounted.current = true;
  }, [defaultFilter, getAllSearchParams, updateSearch]);

  useEffect(() => {
    if (
      isSearchParamsLoaded.current &&
      prevLocation.current === location.search
    ) {
      return;
    }

    const filterWithSearch = getFilterWithSearch(defaultFilter);
    setFilter(filterWithSearch);
    isSearchParamsLoaded.current = true;
    prevLocation.current = location.search;
  }, [defaultFilter, getFilterWithSearch, location]);

  function applyFilter<T extends keyof F>(
    filterName: T,
    value: FilterClickable | FilterRange | string | number
  ) {
    updateSearch(filterName.toString(), value);
  }

  function applyFilters<T extends keyof F>(
    newFilters: Partial<Record<T, F[T]>>
  ) {
    setFilter((prevFilter) => ({ ...prevFilter, ...newFilters }));
  }

  function clearFilter(filterName: keyof F) {
    updateSearch(filterName.toString());
  }

  function reset() {
    clearSearchParams();
  }

  return { filter, applyFilter, clearFilter, applyFilters, reset };
}
