import { FC, useContext } from "react";
import { Redirect, Route } from "react-router-dom";

import Props from "./Props";
import { AppRoute as AppRoute, getRouteWithSlash } from "@/router/AppRoute";
import { AuthContext } from "@/auth/components/AuthContextProvider";

const PrivateRoute: FC<Props> = (routeProps: Props) => {
  const { children, roles, ...rest } = routeProps;
  const { accessToken, role } = useContext(AuthContext);

  return (
    <Route
      {...rest}
      render={(props) => {
        if (!accessToken || !roles.includes(role)) {
          return (
            <Redirect
              to={{
                pathname: getRouteWithSlash(AppRoute.HOME),
                state: { from: props.location },
              }}
            />
          );
        }

        return children;
      }}
    />
  );
};

export default PrivateRoute;
